<template>
  <div>
    <div class="content" @click="showtype = false">
      <div class="all" style="position: relative">
        <div class="SearchBar" ref="viewBox">
          <!--搜索框-->
          <el-form ref="search" :inline="true" :model="search" size="small">
            <el-form-item label="第三方施工队" class="lab">
              <el-input
                v-model="search.teamName"
                placeholder="请输入"
                class="SearchInput"
                clearable
              ></el-input>
            </el-form-item>

            <div ref="viewContract"  style="display: inline-block;">
              <el-form-item label="合同类型" class="lab viewContract">
                <el-input
                  @click.native.stop="showtype = true"
                  v-model="search.type"
                  placeholder="请选择"
                  class="SearchInput"
                  clearable
                  @clear="clearType"
                  readonly
                >
                  <template v-slot:suffix @click.stop="clearType">
                    <i
                      @click.stop="clearType"
                      :class="
                        search.type
                          ? 'el-icon-circle-close'
                          : 'el-icon-caret-bottom'
                      "
                    ></i>
                  </template>
                </el-input>
                <div
                  class="searchType"
                  @click.stop="showtype = true"
                  :style="style"
                  v-if="showtype"
                >
                  <div style="margin: 10px">
                    <el-radio-group
                      v-model="search.contractTypeId"
                      @change="changeChildSearch"
                    >
                      <div v-for="item in options" :key="item.id">
                        <el-radio
                          class="radioList"
                          :label="item.id"
                          :key="item.id"
                          >{{ item.name }}</el-radio
                        >
                        <el-checkbox-group
                          @change="changeLabelSearch"
                          v-model="searchContractTypeChildIdArr"
                          style="margin-left: 20px"
                          :disabled="isCheckBokS == 1 ? true : false"
                          v-if="item.children && item.children.length > 0"
                        >
                          <el-checkbox
                            v-for="itemc in item.children"
                            :label="itemc.id"
                            :key="itemc.id"
                            style="width: 140px"
                            >{{ itemc.name }}</el-checkbox
                          >
                        </el-checkbox-group>
                      </div>
                    </el-radio-group>
                  </div>
                </div>
              </el-form-item>
            </div>
            <el-form-item label="状态" class="lab">
              <el-select
                v-model="search.applyStatus"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="申请时间" class="lab">
              <el-date-picker
                v-model="searchDatatime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                clearable
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="申请单号" class="lab" label-width="95px">
              <el-input
                v-model="search.applyCode"
                placeholder="请输入"
                class="SearchInput"
                clearable
              ></el-input>
            </el-form-item>
            <span>
              <el-button
                type="primary"
                class="SearchBtn"
                size="small"
                @click="getList(1)"
                >查询</el-button
              >
              <el-button
                type="primary"
                plain
                class="SearchBtn2"
                size="small"
                @click="reset"
                >重置</el-button
              >
            </span>
          </el-form>
        </div>
        <div class="table">
          <div style="float: right; margin-bottom: 10px">
            <span>
              <el-button type="primary" @click="showadd" size="small" plain
                >申请</el-button
              ></span
            >
          </div>
          <!--数据表格-->
          <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%; margin: 15px 0"
            border
            height="calc(100vh - 432px)"
            :stripe="true"
            @sort-change="sort_change"
            :default-sort="{ prop: 'applyTime', order: 'descending' }"
          >
            <el-table-column
              type="index"
              label="序号"
              width="70"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="applyCode"
              label="申请单号"
              align="center"
              show-overflow-tooltip
              width="180"
            >
            </el-table-column>
            <el-table-column
              prop="teamName"
              label="第三方施工队"
              align="center"
              show-overflow-tooltip
              width="250"
            >
            </el-table-column>
            <el-table-column
              prop="contractTypeName"
              label="申请合同类型"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="applyUser"
              label="申请人"
              align="center"
              show-overflow-tooltip
              width="180"
            >
            </el-table-column>
            <el-table-column
              prop="applyTime"
              label="申请时间"
              align="center"
              show-overflow-tooltip
              sortable="custom"
              width="200"
            >
            </el-table-column>
            <el-table-column
              prop="applyStatusName"
              label="状态"
              align="center"
              show-overflow-tooltip
              width="120"
            >
              <template slot-scope="{ row }">
                <el-tag
                  v-if="row.applyStatus == 1"
                  type="success"
                  size="small"
                  plain
                  >{{ row.applyStatusName }}</el-tag
                >
                <el-tag
                  v-if="row.applyStatus == 2"
                  type="primary"
                  size="small"
                  plain
                  >{{ row.applyStatusName }}</el-tag
                >
                <el-tag
                  v-if="row.applyStatus == 3"
                  type="warning"
                  size="small"
                  plain
                  >{{ row.applyStatusName }}</el-tag
                >
                <el-tag
                  v-if="row.applyStatus == 4"
                  type="danger"
                  size="small"
                  plain
                  >{{ row.applyStatusName }}</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="operation"
              label="操作"
              align="left"
              width="300"
            >
              <template slot-scope="{ row }">
                <el-button
                  v-if="row.applyStatus == 1"
                  type="primary"
                  size="small"
                  @click="edit(row)"
                  >编辑</el-button
                >
                <el-button
                  v-if="
                    row.applyStatus == 2 ||
                    row.applyStatus == 3 ||
                    row.applyStatus == 4
                  "
                  type="primary"
                  size="small"
                  @click="desc(row)"
                  >查看</el-button
                >
                <el-button
                  v-if="row.applyStatus == 1"
                  type="success"
                  size="small"
                  @click="apply(row)"
                  >送审</el-button
                >
                <el-button
                  v-if="row.applyStatus == 4"
                  type="danger"
                  size="small"
                  @click="showbh(row)"
                  >驳回原因</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pageBox">
            <el-pagination
              :current-page="search.current"
              :background="true"
              :page-sizes="[30, 50, 100]"
              :page-size="search.size"
              layout="total, prev, pager, next, sizes, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="资质申请"
      :visible.sync="dialogVisible"
      width="35%"
      :close-on-click-modal="false"
      @open="openD()"
      @close="clearnF('form')"
    >
      <el-form
        ref="addform"
        :model="form"
        label-width="100px"
        :rules="rule"
        label-position="top"
        size="small"
      >
        <div>
          <el-form-item label="申请单号" v-if="this.form.id">
            <el-input type="text" v-model="form.applyCode" disabled></el-input>
          </el-form-item>
          <el-form-item label="施工队" prop="teamId">
            <el-select
              v-model="form.teamId"
              @change="getItem"
              value-key="id"
              style="width: 400px"
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in teamList"
                :key="item.id"
                :label="item.teamName"
                :value="item.id"
                style="max-width: 400px"
              >
              </el-option>
            </el-select>
            <el-button
              type="primary"
              @click="showItemAdd"
              plain
              style="margin-left: 20px"
              >创建施工队</el-button
            >
          </el-form-item>
          <el-form-item label="签合同类型" prop="contractTypeId">
            <el-radio-group v-model="form.contractTypeId" @change="changeChild">
              <div v-for="item in options1" :key="item.id">
                <el-radio class="radioList" :label="item.id" :key="item.id">{{
                  item.name
                }}</el-radio>
                <el-checkbox-group
                  v-model="form.contractTypeChildIdArr"
                  style="margin-left: 20px"
                  :disabled="isCheckBok == 1 ? true : false"
                  v-if="item.children && item.children.length > 0"
                >
                  <el-checkbox
                    v-for="itemc in item.children"
                    :label="itemc.id + ''"
                    :key="itemc.id"
                    style="width: 140px"
                    >{{ itemc.name }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
            </el-radio-group>
          </el-form-item>
          <el-form-item label=" 施工资质+合同样本" prop="urlList">
            <span slot="label">
              施工资质+合同样本<span style="color: red; margin-left: 4px"
                >*</span
              >
            </span>
            <el-upload
              ref="upload"
              action="#"
              :auto-upload="true"
              :http-request="repairBeforUploadFun"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :before-upload="beforUploadImg"
              :on-change="beforHFhandleChangeImg"
              :file-list="repairBeforFilefileList"
              accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
              :limit="20"
              :on-exceed="msgLimit"
            >
              <div style="height: 20px">
                <img src="../../assets/image/upload.png" />
              </div>
              <div>
                <span>上传图片</span>
              </div>
              <!-- <i class="el-icon-plus"></i> -->
            </el-upload>
          </el-form-item>
          <el-form-item label="施工说明">
            <el-input
              type="textarea"
              v-model="form.note"
              :autosize="{ minRows: 2, maxRows: 4 }"
              maxlength="100"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button style="margin-right: 10px" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button type="primary" @click="saveadd">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="资质申请审批"
      :visible.sync="dialogVisibleDesc"
      width="30%"
      :close-on-click-modal="false"
      @close="clearnF('form')"
    >
      <el-form ref="addform" :model="form" label-width="120px" size="mini">
        <div v-if="isturn != 1">
          <el-form-item label="申请单号：" prop="positionName">
            {{ form.applyCode }}
          </el-form-item>
          <el-form-item label="施工队：" prop="positionName">
            {{ form.teamName }}
          </el-form-item>
          <el-form-item label="签合同类型：" prop="positionCode">
            {{ form.contractTypeName }}
          </el-form-item>
          <el-form-item label=" 施工资质+合同样本:" prop="positionCode">
            <el-image
              style="width: 100px; height: 100px"
              :src="form.urlList[0]"
              :preview-src-list="form.urlList"
            >
            </el-image>
            <span style="margin-left: 10px">共{{ form.urlList.length }}张</span>
          </el-form-item>
          <el-form-item label="施工说明:" prop="positionCode">
            <span style="word-break: break-all">{{ form.note }}</span>
          </el-form-item>
        </div>
        <div v-if="form.applyStatus == 3 || form.applyStatus == 4">
          <el-divider v-if="isturn != 1" content-position="left"
            >审核信息</el-divider
          >
          <el-form-item label="审核结果:">
            <span style="word-break: break-all">{{ form.checkResult }}</span>
          </el-form-item>
          <el-form-item label="驳回原因:" v-if="form.checkNote">
            <span style="word-break: break-all">{{ form.checkNote }}</span>
          </el-form-item>
          <el-form-item label="审核人:">
            <span style="word-break: break-all">{{ form.checkUser }}</span>
          </el-form-item>
          <el-form-item label="审核时间:">
            <span style="word-break: break-all">{{ form.checkTime }}</span>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button style="margin-right:10px" @click="dialogVisibleDesc = false">取消</el-button> -->
      </div>
    </el-dialog>
    <el-dialog
      title="图片预览"
      :visible.sync="dialogVisibleImg"
      width="100%"
      :close-on-click-modal="false"
    >
      <div><img :src="dialogImageUrl" width="100%" /></div>
    </el-dialog>
    <teamAdd ref="teamAdd" :source="source"></teamAdd>
  </div>
</template>
<script>
import {
  apply_page,
  applyStatus,
  apply_findById,
  apply_censorship,
  constructionTeam_getTeamDic,
  uploadImg,
  apply_save,
  apply_update,
} from "../../RequestPort/apply/apply.js";
import { selectDictListByParentCode } from "@/apis/commonType";
import teamAdd from "./teamAdd.vue";
export default {
  components: { teamAdd },
  name: "applyList",
  mixins: [],
  data() {
    return {
      icon_suffix: "",
      search: { current: 1, size: 100, type: "" }, //搜索
      searchContractTypeChildIdArr: [],
      searchDatatime: [],
      total: 10,
      tableData: [{ enterWarehouseName: 1 }], //列表对象
      options: [
        { id: 1, name: "ceso" },
        {
          id: 2,
          name: "ceso111",
          children: [
            { id: 1, name: "ceso" },
            { id: 2, name: "ceso111" },
          ],
        },
      ],
      statusList: applyStatus(),
      dialogVisible: false,
      form: { contractTypeChildIdArr: [], urlList: [] },
      rule: {
        teamId: { required: true, message: "请选择施工队", trigger: "change" },
        contractTypeId: {
          required: true,
          message: "请选择签订合同类型",
          trigger: "blur",
        },
        //  urlList:{ required: true, message: "请上传施工资质", trigger: "blur" },
      },
      dialogVisibleDesc: false,
      isCheckBok: true,
      isCheckBokS: true,
      parentName: "",
      showtype: false,
      teamList: [],
      repairBeforFileFormData: new FormData(),
      repairBeforFilefileList: [],
      isturn: 0,
      source: 1,
      dialogVisibleImg: false,
      dialogImageUrl: "",
      options1: [],
      dom: null,
      style: {
        left: 0,
      },
      left: 0,
    };
  },
  mounted() {
    this.getOption();
    this.getList();
    this.left = this.$refs.viewContract.offsetLeft + 90;
    this.style.left = this.left + "px";
    this.dom = this.$refs.viewBox;
    this.dom.addEventListener("scroll", this.handleFun);
  },
   beforeDestroy() {
    if (this.dom)this.dom.removeEventListener("scroll",this.handleFun);
  },
  methods: {
    handleFun() {
      this.style.left = this.left - this.dom.scrollLeft + "px";
    },
    sort_change(column) {
      this.search.sortColumn = column.prop;
      if (column.order == "ascending") {
        this.search.sortKey = 0;
      } else {
        this.search.sortKey = 1;
      }
      this.getList(1);
    },
    changeMask(flag) {
      console.log(2222);
    },
    clearType() {
      if (this.search.type) {
        this.search.contractTypeId = "";
        this.searchContractTypeChildIdArr = [];
        this.search.contractTypeChildId = "";
        this.isCheckBokS = true;
        this.search.type = "";
        this.showtype = false;
      }
    },
    getOption() {
      selectDictListByParentCode({ parentCode: "hetong", level: 2 }).then(
        (res) => {
          if (res.code == 200) {
            this.options = res.data;
          }
        }
      );
    },
    changeChildSearch(val) {
      this.options.forEach((el) => {
        if (el.id == val) {
          if (el.children) {
            this.isCheckBokS = false;
          } else {
            this.isCheckBokS = true;
            this.searchContractTypeChildIdArr = [];
          }
          this.search.type = el.name;
          this.parentName = el.name;
        }
      });
    },
    changeLabelSearch(val) {
      var namekey = {};
      this.options.forEach((el) => {
        if (el.id == this.search.contractTypeId) {
          el.children.forEach((item) => {
            namekey[item.id] = item.name;
          });
          var name = [];
          val.forEach((items) => {
            name.push(namekey[items]);
          });
          let showname = name.toString();
          if (showname) {
            this.search.type = this.parentName + "(" + showname + ")";
          }
        }
      });
    },
    changeChild(val) {
      this.options.forEach((el) => {
        if (el.id == val) {
          if (el.children) {
            this.isCheckBok = false;
            this.form.contractTypeChildIdArr = [];
          } else {
            this.isCheckBok = true;
            this.form.contractTypeChildIdArr = [];
          }
        }
      });
    },
    repairBeforUploadFun(e) {
      this.repairBeforFileFormData.append("file", e.file);
      uploadImg(this.repairBeforFileFormData).then((res) => {
        this.form.urlList.push(res.data.url);
        this.repairBeforFileFormData.delete("file");
      });
    },
    beforUploadImg(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);

      const whiteList = [
        "jpg",
        "jpeg",
        "png",
        "gif",
        "bmp",
        "JPG",
        "JPEG",
        "PBG",
        "GIF",
        "BMP",
      ];
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error("上传文件只能是 jpg、jpeg、png、gif、bmp");
        return false;
      }
      const size = file.size / 1024 / 1024;
      if (size > 100) {
        this.$message.error("大小必须小于100M");
        return false;
      }
    },
    msgLimit(file, fileList) {
      this.$message.error("只能上传20张图片");
    },
    beforHFhandleChangeImg(file, fileList) {
      this.repairBeforFilefileList = fileList;
    },
    handleRemove(file, fileList) {
      let index = this.getArrayIndex(this.repairBeforFilefileList, file);
      this.form.urlList.splice(index, 1);
    },
    getArrayIndex(arr, obj) {
      var i = arr.length;
      while (i--) {
        if (arr[i] === obj) {
          return i;
        }
      }
      return -1;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisibleImg = true;
    },
    getList(current) {
      if (this.searchDatatime) {
        this.search.startTime = this.searchDatatime[0];
        this.search.endTime = this.searchDatatime[1];
      }
      this.search.contractTypeChildId = "";
      if (this.searchContractTypeChildIdArr.length > 0) {
        this.search.contractTypeChildId =
          this.searchContractTypeChildIdArr.toString();
      }
      if (current) {
        this.search.current = 1;
      }
      //查列表
      apply_page(this.search).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },

    //重置查询条件
    reset() {
      (this.search = {
        current: 1,
        size: 100,
      }),
        (this.searchContractTypeChildIdArr = []);
      this.searchDatatime = [];
      this.getList(1);
    },
    getItem(id) {
      var select = this.teamList.find((val, index, arr) => {
        return val.id == id;
      });
      var type = select.typeCode;

      // this.form.teamId = item.id;
      var showItem = {};
      this.options.forEach((item) => {
        if (type == 2 && item.code == "tufang") {
          showItem = item;
        }
        if (type == 1 && item.code == "2100") {
          showItem = item;
        }
        if (type == 3 && item.code == "dingxiang") {
          showItem = item;
        }
        if (type == 4 && item.code == "qita") {
          showItem = item;
        }
      });
      this.options1 = [showItem];
    },

    handleSizeChange(val) {
      this.search.size = val;
      this.getList(1);
    },
    handleCurrentChange(val) {
      this.search.current = val;
      this.getList();
    },
    showadd() {
      this.getTeam();
      this.dialogVisible = true;
    },
    getTeam() {
      constructionTeam_getTeamDic({}).then((res) => {
        if (res.code == 200) {
          this.teamList = res.data;
        }
      });
    },
    openD() {
      if (this.form.teamId) {
        this.getItem(this.form.teamId);
      } else {
        this.options1 = this.options;
      }
    },
    clearnF() {
      this.form = {
        contractTypeChildIdArr: [],
        urlList: [],
        contractTypeId: "",
        note: "",
        teamId: "",
        id: 0,
      };
      this.repairBeforFilefileList = [];

      if (this.$refs["addform"]) {
        this.$refs["addform"].resetFields();
      }
    },
    saveadd() {
      this.$refs["addform"].validate((valid) => {
        if (valid) {
          var isleaf = 0;
          this.options.forEach((el) => {
            if (el.id == this.form.contractTypeId) {
              if (el.children && this.form.contractTypeChildIdArr.length <= 0) {
                isleaf = 1;
              }
            }
          });
          if (isleaf == 1) {
            this.$message.error("二级合同，至少选择一项");
            return;
          }
          if (this.form.urlList.length <= 0) {
            this.$message.error("请上传施工资质+合同样本");
            return;
          }
          if (this.form.contractTypeChildIdArr.length > 0) {
            this.form.contractTypeChildId =
              this.form.contractTypeChildIdArr.toString();
          }
          if (this.form.id) {
            apply_update(this.form).then((res) => {
              if (res.code == "200") {
                this.$message.success("编辑成功");
                this.clearnF();
                this.getList(1);
                this.dialogVisible = false;
              }
            });
          } else {
            apply_save(this.form).then((res) => {
              if (res.code == "200") {
                this.$message.success("添加成功");
                this.clearnF();
                this.getList(1);
                this.dialogVisible = false;
              }
            });
          }
        }
      });
    },
    desc(row) {
      this.isturn = 0;
      apply_findById({ id: row.id }).then((res) => {
        if (res.code == 200) {
          this.form = res.data;
          this.form.urlList;
          this.dialogVisibleDesc = true;
        }
      });
    },
    showbh(row) {
      this.isturn = 1;
      apply_findById({ id: row.id }).then((res) => {
        if (res.code == 200) {
          this.form = res.data;
          this.form.urlList;
          this.dialogVisibleDesc = true;
        }
      });
    },
    showItemAdd() {
      this.$refs.teamAdd.setdialogVisible(true);
    },
    edit(row) {
      constructionTeam_getTeamDic({}).then((res) => {
        if (res.code == 200) {
          this.teamList = res.data;
          apply_findById({ id: row.id }).then((res) => {
            if (res.code == 200) {
              // this.form = res.data;
              // console.log(this.form);
              this.form.id = res.data.id;
              // this.$set(this.form,'teamId',res.data.teamId)
              this.form.teamId = Number(res.data.teamId);
              this.form.urlList = res.data.urlList;
              if (res.data.note) {
                this.form.note = res.data.note;
              }
              this.form.applyCode = res.data.applyCode;
              this.form.contractTypeId = res.data.contractTypeId;
              res.data.urlList.forEach((el) => {
                var sub = { url: el };
                this.repairBeforFilefileList.push(sub);
              });
              this.options.forEach((el) => {
                if (el.id == res.data.contractTypeId) {
                  if (el.children && el.children.length > 0) {
                    this.isCheckBok = false;
                    if (res.data.contractTypeChildId) {
                      this.form.contractTypeChildIdArr =
                        res.data.contractTypeChildId.split(",");
                    } else {
                      this.form.contractTypeChildIdArr = [];
                    }
                  } else {
                    this.isCheckBok = true;
                    this.form.contractTypeChildIdArr = [];
                  }
                }
              });
              this.dialogVisible = true;
            }
          });
        }
      });
    },
    apply(row) {
      this.$confirm("确定要送审吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal:false,
        type: "warning",
      }).then(() => {
        let params = {
          id: row.id,
        };
        apply_censorship(params).then((res) => {
          if (res.code == 200) {
            this.$message.success("送审成功");
          }
          this.getList();
        });
      });
    },
  },
};
</script>

<style  lang="less" scoped>
::v-deep .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.searchType {
  width: 210px;
  box-shadow: 1px 1px 5px #888888;
  position: absolute;
  z-index: 100;
  background-color: #fff;
}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  // padding-bottom: 1%;
  background-color: #ffffff;
}
.SearchInput {
  width: 100%;
}
.SearchOptions {
  width: 100%;
}
.SearchBtn {
  margin-left: 3.7%;
}
.SearchBtn2 {
  margin-left: 20px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
}

.all {
  background-color: #f3f4f8;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
}
.diaBtn {
  margin-right: 2%;
}
.diaInput {
  width: 82%;
}
.lab {
  margin-left: 20px;
}
.dialog_form {
  margin: 30px;
  padding: 10px;
}
.red {
  color: #f74b4b;
}
.radioList {
  display: block;
  margin: 10px 0px;
}
::v-deep.viewContract .el-form-item__content {
  position: initial;
}
</style>
